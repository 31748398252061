import { Flex, Heading, HStack, Text } from "@chakra-ui/layout";
import { FaAndroid, FaApple } from "react-icons/fa";
import { Button } from "@chakra-ui/button";
import { ReactElement } from "react";
import { Image } from "@chakra-ui/image";

import Background from "../components/Background";
import NavBar from "../components/NavBar";

interface IDownloadButton {
  icon: ReactElement;
  color: string;
  bgColor: string;
}

const LandingPage: React.FC = () => {
  return (
    <>
      <NavBar withButton />
      <Background>
        <Flex
          position="relative"
          direction={{ base: "column", md: "row" }}
          zIndex="1"
          height="100%"
          justifyContent={{ base: "center", md: "space-between" }}
          alignItems="center">
          <Flex
            justifyContent="center"
            direction="column"
            textAlign={{ base: "center", md: "left" }}>
            <Heading
              as="h1"
              fontSize={{ base: "4rem", md: "6rem" }}
              color="#EA4492">
              MAZI
            </Heading>
            <Text
              fontSize={{ base: "1.4rem", md: "2rem" }}
              width={{ md: "600px" }}
              color="white"
              fontWeight="light">
              Download our application for a better experience.
            </Text>
            <HStack
              justifyContent={{ base: "center", md: "flex-start" }}
              mt="2rem"
              ml={{ md: "3rem" }}>
              <DownloadButton
                icon={<FaAndroid />}
                color="white"
                bgColor="green.400"
              />
              <DownloadButton
                icon={<FaApple />}
                color="black"
                bgColor="white"
              />
            </HStack>
          </Flex>
          <Image
            src="images/phone.png"
            transform={{ md: "rotate(15deg)" }}
            display={{ base: "none", md: "block" }}
            mr={{ md: "8rem" }}
            height={{ md: "105%" }}
          />
        </Flex>
      </Background>
    </>
  );
};

const DownloadButton: React.FC<IDownloadButton> = ({
  icon,
  color,
  bgColor,
}) => {
  return (
    <Button
      leftIcon={icon}
      fontSize={{ base: "1rem", md: "1.5rem" }}
      color={color}
      bgColor={bgColor}
      _hover={{ bgColor: "" }}
      _active={{ bgColor: "" }}>
      Download
    </Button>
  );
};

export default LandingPage;
