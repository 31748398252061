import { Flex, Image, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const NavBar: React.FC<{ withButton?: boolean }> = ({ withButton = false }) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      height="10vh"
      px={{ base: "2rem", md: "12rem" }}>
      <Image src="images/mazi.png" height={{ base: "5rem", md: "6rem" }} />
      {withButton && (
        <Button
          as={Link}
          to="/register"
          bgGradient="linear(to-r, gradientLeft, gradientRight)"
          color="white"
          fontSize={{ base: "1rem", md: "1.3rem" }}
          _hover={{ bgColor: "transparent" }}
          _active={{ bgColor: "transparent" }}>
          Register
        </Button>
      )}
    </Flex>
  );
};

export default NavBar;
