import { Button } from "@chakra-ui/button";
import { Flex, Heading, Text, VStack } from "@chakra-ui/layout";
import { Link } from "react-router-dom";
import { useToast } from "@chakra-ui/toast";
import React, { useState } from "react";
import axios from "axios";

import Background from "../components/Background";
import Form from "../components/Form";
import NavBar from "../components/NavBar";

const RegisterPage: React.FC = () => {
  const toast = useToast();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://localhost:8000/auth/register", {
        firstName,
        lastName,
        email,
        password,
        confirmationPassword: confirmPassword,
      });
      if (response && response.data) {
        toast({
          description: "A confirmation email has been sent to your account.",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
      }
      console.log("User registered");
    } catch (error) {
      toast({
        description: "An error occured when registering",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.log(error);
    }
  };

  return (
    <>
      <NavBar />
      <Background>
        <Flex
          as="form"
          onSubmit={handleSubmit}
          position="relative"
          zIndex="1"
          height="100%"
          direction="column"
          textAlign="center"
          alignItems="center">
          <Heading
            as="h1"
            fontSize={{ base: "2rem", md: "4rem" }}
            color="#EA4492"
            mt="2rem">
            Welcome to Mazi
          </Heading>
          <Text
            fontSize={{ base: "1rem", md: "2rem" }}
            fontWeight="light"
            color="white"
            width={{ md: "700px" }}>
            Register and download our application to have a better experience
          </Text>
          <Form
            setFirstName={setFirstName}
            setLastName={setLastName}
            setEmail={setEmail}
            setPassword={setPassword}
            setConfirmPassword={setConfirmPassword}
          />
          <VStack
            position={{ md: "absolute" }}
            mt={{ base: "2rem" }}
            bottom="-55px">
            <Button
              type="submit"
              color="white"
              bgGradient="linear(to-r, gradientLeft, gradientRight)"
              fontSize={{ base: "1.4rem", md: "1.7rem" }}
              _active={{ bgColor: "" }}
              _hover={{ bgColor: "" }}
              py={{ base: "1rem", md: "1.2rem" }}
              px="4rem">
              Register
            </Button>
            <Button
              as={Link}
              to="/"
              color="white"
              bgColor="#428CD4"
              fontSize={{ base: "1.4rem", md: "1.7rem" }}
              _active={{ bgColor: "" }}
              _hover={{ bgColor: "" }}
              py={{ base: "1rem", md: "1.5rem" }}
              px="2rem">
              Back
            </Button>
          </VStack>
        </Flex>
      </Background>
    </>
  );
};

export default RegisterPage;
