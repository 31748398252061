import { Box } from "@chakra-ui/layout";

const Background: React.FC = ({ children }) => {
  return (
    <Box mx={{ md: "8rem" }} mt={{ md: "2rem" }}>
      <Box
        position="relative"
        px={{ base: "2rem", md: "8rem" }}
        height={{ base: "80vh", md: "75vh" }}
        _before={{
          content: '""',
          top: 0,
          position: "absolute",
          left: 0,
          width: "100%",
          height: "100%",
          opacity: "30%",
          bgGradient: "linear(to-r, gradientLeft, gradientRight)",
        }}>
        {children}
      </Box>
    </Box>
  );
};

export default Background;
