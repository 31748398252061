import { Input } from "@chakra-ui/input";
import { HStack, Text, VStack } from "@chakra-ui/layout";
import React from "react";

const Form: React.FC<any> = ({
  setFirstName,
  setLastName,
  setEmail,
  setPassword,
  setConfirmPassword,
}) => {
  return (
    <VStack
      width="100%"
      spacing="1rem"
      mt="2rem"
      alignItems="start"
      color="white">
      <Text fontSize={{ base: "1rem", md: "1.2rem" }}>
        Please fill out the form
      </Text>
      <HStack width="100%" spacing={{ base: "1rem", md: "4rem" }}>
        <CustomInput
          placeholder="First Name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFirstName(e.target.value)
          }
        />
        <CustomInput
          placeholder="Last Name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setLastName(e.target.value)
          }
        />
      </HStack>
      <CustomInput
        placeholder="Email Address"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setEmail(e.target.value)
        }
      />
      <CustomInput
        placeholder="Password"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setPassword(e.target.value)
        }
        type="password"
      />
      <CustomInput
        placeholder="Confirm Password"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setConfirmPassword(e.target.value)
        }
        type="password"
      />
    </VStack>
  );
};

const CustomInput: React.FC<{
  placeholder: string;
  onChange: React.ChangeEventHandler;
  type?: string;
}> = ({ placeholder, onChange, type }) => {
  return (
    <Input
      placeholder={placeholder}
      size="lg"
      type={type}
      borderColor="primary"
      onChange={onChange}
    />
  );
};

export default Form;
