import { extendTheme } from "@chakra-ui/react";

const colors = {
  primary: "#051A2D",
  gradientLeft: "#FF9CDA",
  gradientRight: "#9D91D6",
};

const styles = {
  global: () => ({
    body: {
      bg: "primary",
    },
  }),
};

const theme = extendTheme({ styles, colors });

export default theme;
